import { Component, OnInit } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { PrimeNG } from 'primeng/config';
import { ModalWindowComponent } from "./modal-window/modal-window.component";
import { RouterOutlet } from "@angular/router";
import { Store } from "@ngrx/store";
import { restoreSavedData } from "./store/auth/auth.actions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    ModalWindowComponent,
    RouterOutlet,
    TranslateModule
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private config: PrimeNG,
              private translateService: TranslateService,
              private store: Store) {
  }

  ngOnInit() {
    this.initTranslations();
    this.store.dispatch(restoreSavedData());
  }

  initTranslations() {
    this.translateService.setDefaultLang('ru-RU');
    this.translateService.use('ru-RU').subscribe(() => {
      this.translate();
    });
  }

  translate() {
    this.translateService.get('primeng').subscribe(res => {
      this.config.setTranslation(res);
    });
  }
}

